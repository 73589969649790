@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.Form {
	@include coloredComponent;
	padding: var(--spacing--5xl) 0;

	@include mq("md") {
		padding: var(--spacing--4xl) 0;
	}
}

.Form_trumpetHeading {
	@include body--lg-reg;
	padding-bottom: var(--spacing--2xl);

	@include mq("md") {
		padding-bottom: var(--spacing--4xl);
	}
}

.Form_heading {
	padding-bottom: var(--spacing--lg);

	@include mq("md") {
		padding-bottom: var(--spacing--2xl);
	}
}

.Form_image {
	padding-bottom: var(--spacing--lg);

	@include mq("md") {
		padding-bottom: 0;
	}
}

.Form_formFieldString {
	padding-bottom: var(--spacing--base);
}

// Theme: Kogp
[data-theme='kogp'] {
	.Form_trumpetHeading {
		font-weight: var(--font-weight--extrablack);
	}
}