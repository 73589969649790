.Ortto_textContainer {
	position: relative;
	overflow: hidden;
}

.Ortto_ButtonText {
	&___animationText {
		display: none;
	}
}

.Ortto_Button,
.Ortto_Button:disabled {
	.Ortto_ButtonText {
		transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		position: relative;
		display: block;

		&___animationText {
			display: block;
			position: absolute;
			top: 0;
			transform: translateY(101%);
			transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}
	}

	&:hover {
		.Ortto_ButtonText {
			transform: translateY(-101%);

			&___animationText {
				transform: translateY(0%);
			}
		}
	}

	&:disabled {
		opacity: 0.3;
		cursor: not-allowed;

		&:hover,
		&:focus {
			transition: none;
		}
	}
}
